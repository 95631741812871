import React from "react"

const outerStyle = {
  backgroundColor: "whitesmoke",
  position: "absolute",
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
}

const innerStyle = {
  color: "#424242",
  position: "absolute",
  width: "30vw",
  top: "35%",
  left: "50%",
  transform: "translate(-50%,-35%)",
}

let buttonStyle = {
  position: "absolute",
  width: "100px",
  height: "40px",
  top: "65%",
  left: "50%",
  transform: "translate(-50%,-65%)",
  border: "none",
  backgroundColor: "#0161f7",
  color: "white",
  fontFamily: "arial",
  fontSize: "18px",
  borderRadius: "20px",
  cursor: "pointer",
}

function InitialMessage({ globalState }) {
  function cool() {
    window.localStorage.messageHasBeenViewed = true
    globalState.updateState({ messageHasBeenViewed: true })
  }

  const { innerWidth } = globalState
  const extraInnerStyles = { ...innerStyle }
  const extraButtonStyles = { ...buttonStyle }

  if (innerWidth < 1440) {
    extraInnerStyles.top = "20%"
    extraInnerStyles.transform = "translate(-50%, -20%)"
    extraInnerStyles.width = "80vw"
    extraButtonStyles.top = null
    extraButtonStyles.bottom = "60px"
    extraButtonStyles.transform = "translate(-50%,0)"
  }

  return (
    <div style={outerStyle}>
      <div style={extraInnerStyles}>
        We want to give Emilio some semblance of privacy as he grows up and not
        have a ton of images connected to his name before being old enough to
        use a computer. We want him to have control over his own personal brand
        and presentation to the world once able. As an alternative, we’ll use
        private channels for friends and family like Google Photos or WhatsApp,
        and of course this site to share images. We hope that's cool with you.
        Thanks for understanding.
      </div>
      <button style={extraButtonStyles} onClick={cool}>
        Cool
      </button>
    </div>
  )
}

export default InitialMessage
